/**
 * @author David Roman <david@inarix.com>
 * @file Login component
 * @desc Created on 2023-08-17 11:28:44 am
 * @copyright Inarix
 */
import React, { FC, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { State } from "../../redux/slices/index";

import {
  setUsername as loginSetUsername,
  setPassword as loginSetPassword,
  resetState as loginResetState,
  disableErrorLogin,
  setRememberMe,
} from "../../redux/slices/authentication/authentication";

import { signIn as loginSignin } from "../../redux/actions/authentication/authentication";
import InarixIcon from "../../assets/images/login/icon-192.png";

import Box from "@mui/material/Box";

import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { inarixColors } from "inarix-theme";
import pjson from "../../../package.json";

import { LoadingCircularProgress } from "../Loading";
import { setLocale } from "../../redux/slices/layout";
import { ThunkDispatch } from "@reduxjs/toolkit";

const helpersLocalStorageItems = (
  //token: string,
  password: string,
  username: string,
  rememberMe: boolean,
  emails: string[]
) => {
  //localStorage.setItem("access_token", token);
  localStorage.setItem("password", password);
  localStorage.setItem("username", username);
  localStorage.setItem("rememberme", `${rememberMe}`);
  if (!emails) {
    localStorage.setItem("emails", `[]`);
  }
};

const SetLocaleActions = () => {
  const dispatch = useDispatch();
  return {
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    setRememberMe: (remberme: boolean) => dispatch(setRememberMe(remberme)),
  };
};

type LoginFormProps = {
  submitHandler: (e: FormEvent) => void;
  checkBox: (value: boolean) => void;
  error: string;
  username: string;
  password: string;
  rememberMe: boolean;
  loading: boolean;
  setUsername: (value: string) => void;
  setPassword: (value: string) => void;
  errorAuthenticated: number | null;
  value: string;
};

//  Login v2

const LoginForm: FC<LoginFormProps> = ({
  submitHandler,
  username,
  password,
  loading,
  rememberMe,
  setUsername,
  setPassword,
  checkBox,
  error,
  errorAuthenticated,
  value,
}: LoginFormProps) => {
  const [t, i18n] = useTranslation("global");
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const { setTranslation } = useSelector(
    (state: State) => state.Authentication
  );

  const { setLocaleLanguage } = SetLocaleActions();
  const errorTranlate = () => {
    if (errorAuthenticated) {
      return t("Login.noAccess");
    } else {
      return "";
    }
  };

  return (
    <Box id="login">
      <Modal open={true} disableAutoFocus disableEscapeKeyDown>
        <Box className="login-container-v2">
          <Card
            data-cy="user-card"
            sx={{
              maxWidth: 408,
              width: 408,
              minWidth: 100,
              height: 515,
              maxHeight: 515,
              margin: "10px",
            }}
          >
            <CardHeader
              sx={{
                background: inarixColors.layout_background,
                color: inarixColors.standard_input_line_inarix,
                height: "97px",
              }}
              avatar={
                <Avatar
                  data-cy="brand-logo-container"
                  variant="rounded"
                  src={InarixIcon}
                  sx={{ width: "65px", height: "65px" }}
                />
              }
              title={t("Login.Portal")}
              titleTypographyProps={{ fontSize: "1.5em", fontWeight: "bold" }}
            />
            {error && errorAuthenticated && (
              <div
                className="alert-content-login mb-5"
                data-cy="message-body-alert"
              >
                <Alert severity="error">
                  {t("Fleet_users.table.Error")} :{" "}
                  {JSON.stringify(errorTranlate())}
                </Alert>
              </div>
            )}
            {error && !errorAuthenticated && (
              <div className="alert-content-login" data-cy="message-body-alert">
                <Alert severity="error">
                  {t("Fleet_users.table.Error")} : {JSON.stringify(error)}{" "}
                </Alert>
              </div>
            )}
            <CardHeader
              title={t("Login.login")}
              sx={{ backgroundColor: "white", color: "black" }}
              titleTypographyProps={{ fontSize: "20px" }}
            />
            <CardContent sx={{ backgroundColor: "white" }}>
              <form onSubmit={submitHandler}>
                <TextField
                  data-cy="username"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={true}
                  placeholder={t("Login.username")}
                  size="medium"
                  value={username}
                  onChange={(e) => setUsername(e.currentTarget.value)}
                  required
                />
                <br />
                <br />
                <TextField
                  type={"password"}
                  data-cy="password"
                  fullWidth={true}
                  variant="outlined"
                  placeholder={t("Login.password")}
                  size="medium"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  required
                />
                <div className="remember-me">
                  <input
                    onChange={() => checkBox(rememberMe)}
                    id="remember-me-login"
                    data-cy="remember-me"
                    type="checkbox"
                    className="remember-me-checkbox"
                    checked={rememberMe}
                  />
                  <label
                    className="remember-me-text"
                    data-cy="remember-me-text"
                  >
                    {t("Login.remember")}
                  </label>

                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel>{t("Login.language")}</InputLabel>
                      <Select
                        value={value}
                        data-cy="select-languages"
                        label="Language"
                      >
                        <MenuItem
                          data-cy="en"
                          value={"en"}
                          disabled={locale === "en"}
                          onClick={() => {
                            i18n.changeLanguage(setTranslation.Eng);
                            setLocaleLanguage("en");
                          }}
                        >
                          ENG
                        </MenuItem>
                        <MenuItem
                          value={"fr"}
                          data-cy="fr"
                          disabled={locale === "fr"}
                          onClick={() => {
                            i18n.changeLanguage(setTranslation.Fr);
                            setLocaleLanguage("fr");
                          }}
                        >
                          FR
                        </MenuItem>
                        <MenuItem
                          value={"es"}
                          data-cy="es"
                          disabled={locale === "es"}
                          onClick={() => {
                            i18n.changeLanguage(setTranslation.Es);
                            setLocaleLanguage("es");
                          }}
                        >
                          ESP
                        </MenuItem>
                        <MenuItem
                          value={"de"}
                          data-cy="de"
                          disabled={locale === "de"}
                          onClick={() => {
                            i18n.changeLanguage(setTranslation.De);
                            setLocaleLanguage("de");
                          }}
                        >
                          DE
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="container-button-login nmt-3">
                  {!loading ? (
                    <Button
                      variant="contained"
                      size="large"
                      data-cy="login"
                      type="submit"
                      className="button-login"
                      sx={{ width: "308px" }}
                    >
                      {t("Login.login")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                </div>
              </form>
              <div className="version-portal">
                <div>v{pjson.version}</div>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setUsername: (value: string) => dispatch(loginSetUsername(value)),
    setPassword: (value: string) => dispatch(loginSetPassword(value)),
    rememberUserOn: () => dispatch(setRememberMe(true)),
    rememberUserOff: () => dispatch(setRememberMe(false)),
    disableError: () => dispatch(disableErrorLogin()),
    signIn: () => {
      dispatch(loginSignin());
    },
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    resetState: () => dispatch(loginResetState()),
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Login = () => {
  const rememberme = localStorage.getItem("rememberme");
  const passwordAlpha = localStorage.getItem("password");
  const usernameAlpha = localStorage.getItem("username");
  const emails = JSON.parse(localStorage.getItem("emails") as string);

  const { username, password, loading, rememberMe, error, errorAuthenticated } =
    useSelector((state: State) => state.Authentication);

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const language = localStorage.getItem("i18nextLng");
  const {
    setUsername,
    setPassword,
    signIn,
    rememberUserOn,
    rememberUserOff,
    disableError,
    setLocaleLanguage,
    resetState,
  } = SetActions();

  const check = () => {
    if (!rememberMe) {
      return rememberUserOn();
    }
    return rememberUserOff();
  };

  if (error || errorAuthenticated) {
    setTimeout(() => {
      disableError();
    }, 5000);
  }

  useEffect(() => {
    resetState();

    if (!rememberme) {
      setUsername("");
      setPassword("");
    } else {
      setUsername(usernameAlpha ?? "");
      setPassword(passwordAlpha ?? "");
    }

    setLocaleLanguage(language ?? "fr");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    signIn();
    helpersLocalStorageItems(password, username, rememberMe, emails);
  };

  return (
    <div>
      <div className="bg" data-cy="backgroundLogin" />
      <div className="form">
        <br />
      </div>
      <LoginForm
        submitHandler={submitHandler}
        error={error}
        username={username}
        password={password}
        loading={loading}
        setUsername={setUsername}
        setPassword={setPassword}
        rememberMe={rememberMe}
        checkBox={check}
        errorAuthenticated={errorAuthenticated}
        value={locale}
      />
    </div>
  );
};

export default Login;
