import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import { useSelector } from "react-redux";
import { State } from "../../../redux/slices";

noDataToDisplay(Highcharts);

const PocketLabChart = (t: (arg0: string) => string) => {
  const { rawRowsPocketLabTable, pocketlabVersion } = useSelector(
    (state: State) => state.DashboardReducer
  );

  const pocketLabVWithoutV = pocketlabVersion.replace("v", "");

  const aboveEqualToCurrentVersion = rawRowsPocketLabTable
    .map((d: any) => {
      if (d.version >= pocketLabVWithoutV) {
        return d;
      }
    })
    .filter(Boolean);

  const minorToCurrentVersion = rawRowsPocketLabTable
    .map((d: any) => {
      if (d.version < pocketLabVWithoutV) {
        return d;
      }
    })
    .filter(Boolean);

  const unknownToCurrentVersion = rawRowsPocketLabTable
    .map((d: any) => {
      if (d.version === null) {
        return d;
      }
    })
    .filter(Boolean);

  const totalOfVersions = rawRowsPocketLabTable.map(
    (d: any) => d.version
  ).length;

  const data = {
    above: (aboveEqualToCurrentVersion.length * 100) / totalOfVersions,
    minor: (minorToCurrentVersion.length * 100) / totalOfVersions,
    unknownVersion: (unknownToCurrentVersion.length * 100) / totalOfVersions,
  } as any;

  const resultPocketLabTranformed = Object.keys(data).map((key) => {
    if (key === "above") {
      return [t("Dashboard.last_version_2"), data[key]];
    }
    if (key === "minor") {
      return [t("Dashboard.another_version"), data[key]];
    }
    if (key === "unknownVersion") {
      return [t("Dashboard.unknown_version"), data[key]];
    }
  });

  const series = [
    {
      type: "pie",
      name: "",
      innerSize: "50%",
      data: resultPocketLabTranformed,
    },
  ] as any;

  const chartOptions: Options = {
    chart: {
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: {
      text: t("Dashboard.distribution_of_user"),
      align: "center",
      verticalAlign: "middle",
      y: 60,
      style: {
        fontSize: "1.1em",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "110%",
      },
    },
    series: series,
  };

  return (
    <HighchartsReact
      containerProps={{
        style: {
          width: "389px",
          //height: "226px",
          height: "426px",
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default PocketLabChart;
