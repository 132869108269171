import React from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import "moment/min/locales";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";

import { GridRenderCellParams } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import { inarixColors } from "inarix-theme";
import {
  setUserDeviceId,
  setDevicesModal,
} from "../../redux/slices/fleet/devices";
import { DColumnsOverview } from "../../redux/actions/data/DataDeclarations";
import { DeviceData } from "../../redux/actions/devices/devicesTypes";
import { State } from "../../redux/slices";

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    setDeviceId: (id: string) => {
      dispatch(setUserDeviceId(id));
      dispatch(setDevicesModal(true));
    },
  };
};

const manageDeviceNameColumn = (
  devicesName: any,
  t: (arg0: string) => string
) => {
  if (devicesName) {
    return (
      <Chip label={devicesName} variant="outlined" className={`d-flex ml-1`} />
    );
  } else {
    return (
      <Chip
        label={t("Fleet_users_devices.table.Empty_device")}
        variant="outlined"
        className="d-flex ml-1"
      />
    );
  }
};

const manageLastContactColumn = (
  devicesLastContact: any,
  t: (arg0: string) => string
) => {
  if (devicesLastContact) {
    const language = localStorage.getItem("i18nextLng");
    const deviceAllotmentdate = moment(devicesLastContact);
    deviceAllotmentdate?.locale(language as string);
    const time = deviceAllotmentdate.format("MMM Do YY");
    const timeC = () => <span>{time}</span>;
    return timeC();
  } else {
    return t("Fleet_users_devices.table.Last_contact_empty");
  }
};

const manageLastLocation = (
  lastLocation: any,
  lastLocationContent: any,
  t: (arg0: string) => string
) => {
  if (!lastLocationContent) {
    return t("Fleet_users_devices.table.Last_location_empty");
  } else {
    return lastLocation;
  }
};

const UserDevicesColumns = (t: (arg0: string) => string) => {
  const { devices } = useSelector((state: State) => state.DevicesReducer);
  const { setDeviceId } = SetActions();

  const columnNames = {
    deviceName: t("Fleet_users_devices.table.Device_name"),
    lastUser: t("Fleet_users_devices.table.Last_user"),
    lastContact: t("Fleet_users_devices.table.Last_contact"),
    lastLocation: t("Fleet_users_devices.table.Last_location"),
    id: t("Fleet_users_devices.table.Device_inarix_id"),
    editDevice: t("Fleet_users_devices.table.Edit_device"),
  };

  const rawColumns = [
    {
      columnField: "deviceName",
      sortable: true,
    },
    {
      columnField: "lastUser",
      sortable: true,
    },

    {
      columnField: "lastContact",
      sortable: true,
    },
    {
      columnField: "lastLocation",
      sortable: true,
    },
    {
      columnField: "id",
      sortable: false,
    },

    {
      columnField: "editDevice",
      sortable: false,
    },
  ];

  let columns: any[] = rawColumns.map((column: DColumnsOverview) => {
    if (column.columnField === "deviceName") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 200,
        sortable: false,

        renderCell: (params: GridRenderCellParams) => {
          const devicesName = params?.value;
          return manageDeviceNameColumn(devicesName, t);
        },
      };
    }

    if (column.columnField === "lastContact") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 200,
        sortable: false,

        renderCell: (params: GridRenderCellParams) => {
          const devicesLastContact = params?.value;
          return manageLastContactColumn(devicesLastContact, t);
        },
      };
    }

    if (column.columnField === "lastLocation") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const lastLocation = params;
          const lastLocationContent = params.value;
          return manageLastLocation(lastLocation, lastLocationContent, t);
        },
      };
    }

    if (column.columnField === "id") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const inarixId = params.value;
          const deviceId = inarixId.slice(0, 8);
          const deviceIdWith = `${deviceId} ...`;

          const chipDevice = () => (
            <Chip
              className={`d-flex ml-1`}
              label={deviceIdWith}
              variant="outlined"
            />
          );
          return chipDevice();
        },
      };
    }

    if (column.columnField === "editDevice") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 200,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const inarixId = params.value;
          const chipIdDevice = () => {
            const idConsole = () => setDeviceId(inarixId);
            return (
              <Tooltip
                title={t("Fleet_users_devices.table.Edit_device" as string)}
                arrow
              >
                <IconButton
                  sx={{
                    color: inarixColors.main_inarix,
                    background: "#ffff",
                    boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                    marginRight: "5px",
                    "&:hover": {
                      color: inarixColors.light_inarix,
                    },
                  }}
                  onClick={idConsole}
                  aria-label="delete"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          };
          return chipIdDevice();
        },
      };
    }

    return {
      field: column.columnField,
      headerName: (columnNames as never)[column.columnField]
        ? (columnNames as never)[column.columnField]
        : column.columnName,
      width: 200,
      sortable: column.sortable,
    };
  });

  let devicesRows = devices?.map((devices: DeviceData) => {
    const rows = {
      deviceName: devices?.orgInternalId,
      lastUser: devices?.deviceAllotment?.user?.username,
      lastContact: devices?.deviceAllotment?.lastContact,
      lastLocation: devices?.organizationGeoPoint?.name?.toLowerCase(),
      id: devices?.id,
      editDevice: devices?.id,
    };

    return rows;
  });

  if (!columns) {
    columns = [];
  }

  if (!devicesRows) {
    devicesRows = [];
  }

  return {
    columns,
    devicesRows,
  };
};

export default UserDevicesColumns;
