import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import axios from "axios";
import { API } from "../../../config";
import { cacheScenarioInfo } from "./actionsAsyncActions";
import { OrganizationUserGroup2, ScenarioData } from "./actionTypes";
import { setActionsRows, setGroupsScopes } from "../../slices/fleet/actions";

export const listScenarios = createAsyncThunk(
  "ActionsReducer/listScenarios",
  async (arg: { token: string; orgId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const url = `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]&$modifyEager[scopes.group][orgId]=${arg.orgId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${arg.token}`,
          "Accept-Language": `${locale}`,
        },
      });
      const { data } = response;
      
      const newRows = (await cacheScenarioInfo(
        data.data.cerealTypeId,
        data.data,
        arg.token,
        store.dispatch,
        locale
      )) as any;

      const actionsRows = newRows?.map((row: ScenarioData) => {
        const iconProps = {
          name: row.scenarioInfo?.generalName,
          iconSalt: row.iconSalt,
          hashSlug: row.scenarioInfo?.slug,
          background: row.scenarioInfo?.iconBackgroundHexColor,
          foreground: row.scenarioInfo?.iconForegroundHexColor,
        };

        const scenarioNameValue = () => {
          if (row.name) {
            let def = "";
            const arr = row.name.localizedTexts
              .map((c: any) => {
                if (locale === c.locale.value) {
                  return c.translation;
                }
                def = c.translation;
              })
              .filter((val: any) => null != val);
            if (!arr.length) {
              return [def].toString();
            } else {
              return arr.toString();
            }
          } else {
            return "Null";
          }
        };

        const scenarioDescriptionValue = () => {
          if (row.desc) {
            let def = "";
            const arr = row.desc.localizedTexts
              .map((c: any) => {
                if (locale === c.locale.value) {
                  return c.translation;
                }
                def = c.translation;
              })
              .filter((val: any) => null != val);
            if (!arr.length) {
              return [def].toString();
            } else {
              return arr.toString();
            }
          } else {
            return "Null";
          }
        };

        const scenarioProps = {
          lastScenarioInstance: row.lastScenarioInstanceId,
          scenarioId: row.id,
        };

        const scenarioGroups = row.scopes.map((l: any) => l.group);

        const scenearioGropsClean = scenarioGroups.filter((element: any) => {
          return element !== null;
        });

        const tableRows = {
          scenarioIcon: iconProps,
          id: row.id,
          scenarioName: scenarioNameValue().toLowerCase(),
          scenarioDescription: scenarioDescriptionValue().toLowerCase(),
          cerealName: row.cerealName?.toLowerCase().toLocaleLowerCase(),
          status: row.status,
          group: scenearioGropsClean,
          details: scenarioProps,
          lastScenarioInstanceId: row.lastScenarioInstanceId,
        };
        return tableRows;
      });

      store.dispatch(setActionsRows(actionsRows));
      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const listGroupsWithScenarioScopes = async (
  token: string,
  groupIds: number[],
  locale: string
): Promise<any> => {
  const queryString = groupIds
    .map((id) => `scopeGroupId[$in][]=${id}`)
    .join("&");

  const res = await axios({
    method: "GET",
    url: `${API}/core/scenario-scope?deletedAt=null&${queryString}&$joinRelation=[scenario]&scenario.statusConstantId[$nin]=0&scenario.statusConstantId[$nin]=2`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": `${locale}`,
    },
  });
  const { data } = res;
  return data;
};

export const listGroups = createAsyncThunk(
  "ActionsReducer/listGroups",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.get(`${API}/users/organization-user-group`, {
        headers,
      });
      const { data } = response;
      const listGroupsScenarioScopesResult = await listGroupsWithScenarioScopes(
        token,
        data.data.map((group: OrganizationUserGroup2) => group.id),
        locale
      );

      store.dispatch(setGroupsScopes(listGroupsScenarioScopesResult.data));
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
