import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { Box, Paper, FormControlLabel, Divider } from "@mui/material";
import { inarixColors } from "inarix-theme";

import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { State } from "../../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  fetchActivityPocketLabDataDashboard,
  fetchActivityPocketLabDataDashboard2,
  fetchGeoLocDataDashboard,
} from "../../../redux/actions/dashboard/dashboardAsyncActions";
import {
  fetchGeoLocDataSuccess,
  initialState,
  resetChartAcivity,
  resetDashboardState,
  setAllSilos,
  setDashboardInput,
  setLocationNameDashboardInput,
  setMultipleLocations,
} from "../../../redux/slices/dashboard/dashboard";
import {
  DashboardLocIdInfo,
  GeoLocaDashboard,
} from "../../../redux/actions/dashboard/dashboardTypes";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setValue: (value: string) => dispatch(setDashboardInput(value)),
    setSelectAll: (setSilos: boolean) => dispatch(setAllSilos(setSilos)),
    fetchActivity: (
      token: string,
      allLocationsActive: boolean,
      id?: DashboardLocIdInfo[]
    ) => {
      if (allLocationsActive) {
        dispatch(setAllSilos(false));
        dispatch(fetchGeoLocDataDashboard({ token, id }));
        dispatch(fetchActivityPocketLabDataDashboard({ token, id }));
        dispatch(fetchActivityPocketLabDataDashboard2({ token, id }));
      } else {
        dispatch(fetchGeoLocDataDashboard({ token, id }));
        dispatch(fetchActivityPocketLabDataDashboard({ token, id }));
        dispatch(fetchActivityPocketLabDataDashboard2({ token, id }));
      }
    },

    fetchActivityAll: (token: string) => {
      dispatch(setAllSilos(true));
      setTimeout(() => {
        dispatch(fetchGeoLocDataDashboard({ token }));
        dispatch(fetchActivityPocketLabDataDashboard({ token }));
        dispatch(fetchActivityPocketLabDataDashboard2({ token }));
      }, 500);
    },

    setLocationNameDashReset: (
      name: string,
      locationsReset: GeoLocaDashboard
    ) => {
      dispatch(setLocationNameDashboardInput(name));
      dispatch(resetDashboardState());
      dispatch(fetchGeoLocDataSuccess(locationsReset));
      dispatch(resetChartAcivity());
    },

    selectLocations: (Locations: DashboardLocIdInfo[]) => {
      dispatch(setMultipleLocations(Locations));
    },
  };
};

const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={dataSet[2]}
      />
      {`${dataSet[1]}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const AutocompleteDashboardInput = (
  token: string,
  t: (arg0: string) => string
) => {
  const {
    fetchActivity,
    setLocationNameDashReset,
    selectLocations,
    fetchActivityAll,
    setSelectAll,
  } = SetActions();

  const {
    locationsNames,
    allLocations,
    locationsSelected,
    loadingLocationsNames,
  } = useSelector((state: State) => state.DashboardReducer);

  const transform = locationsNames.map((c: any) => c);

  const handleToggleSelectAll = () => {
    if (!allLocations) {
      selectLocations([...transform]);
      fetchActivityAll(token);
      setSelectAll(true);
    } else {
      selectLocations([]);
      setLocationNameDashReset("", initialState.geoLocInfo);
      setSelectAll(false);
    }
  };

  return (
    <Autocomplete
      sx={{
        overflowY: locationsSelected.length > 2 ? "scroll" : "hidden",
        height: locationsSelected.length >= 1 ? "90px" : "38px",
        border: 1,
        borderColor: inarixColors.secondary_text_inarix,
        borderRadius: "5px",
        width: 354,
        "&:hover": {
          border: `1.3px solid ${inarixColors.light_inarix}`,
          color: "gray",
        },
      }}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                  handleToggleSelectAll();
                }}
                disabled={loadingLocationsNames}
                label={t("Dashboard.setAllLocations")}
                control={
                  <Checkbox id="select-all-checkbox" checked={allLocations} />
                }
              />
            </Box>
            <Divider />
            {children}
          </Paper>
        );
      }}
      className="autocomplete-dashboard mb-5"
      id={"autocomplete_with_select_all"}
      multiple
      disableListWrap={true}
      options={locationsNames}
      disableCloseOnSelect
      ListboxComponent={ListboxComponent}
      value={locationsSelected}
      getOptionLabel={(option: DashboardLocIdInfo) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_e, value, reason) => {
        if (reason === "removeOption") {
          selectLocations(value);
          setSelectAll(false);
          fetchActivity(token, allLocations, value);
        }
        if (reason === "removeOption" && value.length === 0) {
          return setLocationNameDashReset("", initialState.geoLocInfo);
        }

        if (reason === "clear") {
          return setLocationNameDashReset("", initialState.geoLocInfo);
        }

        if (
          reason === "selectOption" &&
          value.length === locationsNames.length
        ) {
          setSelectAll(true);
          selectLocations(value);
          fetchActivity(token, allLocations, value);
        }
        if (reason === "selectOption" && locationsNames.length === 0) {
          return setLocationNameDashReset("", initialState.geoLocInfo);
        }
        if (reason === "selectOption" && locationsNames.length >= 1) {
          selectLocations(value);
          fetchActivity(token, allLocations, value);
        }
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            marginTop: "-9px",
            "& .MuiInput-underline:after": {
              borderColor: "transparent",
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
          }}
          {...params}
          placeholder={t("Fleet_overview.search_sidebar.search_any")}
        />
      )}
      renderOption={(props, option, { selected }) =>
        [props, option.name, selected] as React.ReactNode
      }
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
};
export default AutocompleteDashboardInput;
