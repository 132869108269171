/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2020-11-16 10:45:38 am
 * @copyright Inarix
 */

import {
  User,
  haveAccess,
  signIn,
  refreshToken,
} from "../../actions/authentication/authentication";
import { createSlice } from "@reduxjs/toolkit";
import pjson from "../../../../package.json";
import { PURGE } from "redux-persist";

export interface AuthState {
  user: User | null;
  username: string;
  password: string;
  authenticated: boolean;
  msg: string;
  loading: boolean;
  rememberMe: boolean;
  error: string;
  errorAuthenticated: null | number;
  noAccess: null | number;
  refreshTimeoutId: undefined | number;
  isThePortalVersionDifferent: boolean;
  isViewerOnly: boolean;
  portalVersion: {
    version: number | string;
  };
  setTranslation: {
    Fr: string;
    Eng: string;
    Es: string;
    De: string;
  };
}

const initialState: AuthState = {
  user: null,
  username: "",
  password: "",
  authenticated: false,
  msg: "",
  loading: false,
  rememberMe: false,
  error: "",
  errorAuthenticated: null,
  noAccess: null,
  refreshTimeoutId: undefined,
  isThePortalVersionDifferent: false,
  isViewerOnly: false,
  portalVersion: {
    version: `${pjson.version}`,
  },
  setTranslation: {
    Fr: "fr",
    Eng: "en",
    Es: "es",
    De: "de",
  },
};

export const AuthSlice = createSlice({
  name: "AuthReducer",
  initialState,
  reducers: {
    setRememberMe(state, action) {
      state.rememberMe = action.payload;
    },

    isViewerOnlyInfo(state, action) {
      state.isViewerOnly = action.payload;
    },
    disableErrorLogin(state) {
      state.error = "";
      state.errorAuthenticated = null;
    },

    resetState(state) {
      state.user = null;
      state.authenticated = false;
      state.msg = "";
      state.loading = false;
      state.error = "";
      state.errorAuthenticated = null;
      state.noAccess = null;
      state.refreshTimeoutId = undefined;
      state.isViewerOnly = false;
    },

    signOut(state) {
      state.user = null;
      state.authenticated = false;
      state.loading = false;
      state.msg = "";
      state.noAccess = null;
    },
    setRefreshTimeout(state, action) {
      state.refreshTimeoutId = action.payload ?? undefined;
    },

    isPortalVersionDifferent(state, action) {
      state.isThePortalVersionDifferent = action.payload;
    },
    setUsername(state, action) {
      state.username = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
    setPortalVersion(state, action) {
      state.portalVersion.version = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(PURGE, () => {
      return initialState;
    });

    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.user = { token: action.payload };
        state.authenticated = true;
        state.error = "";
      })
      .addCase(signIn.rejected, (state, action: any) => {
        state.loading = false;
        state.authenticated = false;
        state.user = null;
        state.error =
          action.payload?.response?.data?.message || action?.payload;
      });

    builder.addCase(haveAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(haveAccess.fulfilled, (state, action) => {
      state.loading = false;
      state.msg = action.payload;
    });
    builder.addCase(haveAccess.rejected, (state, action: any) => {
      state.loading = false;
      state.errorAuthenticated = action.payload?.response?.status;
      state.noAccess = action.payload?.response?.status;
    });

    builder.addCase(refreshToken.pending, (state) => {
      state.loading = true;
      state.error = "";
    });

    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.loading = false;
      state.authenticated = true;
      state.user = { token: action.payload };
      state.error = "";
    });

    builder.addCase(refreshToken.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  loading,
  resetState,
  setUsername,
  setPassword,
  isPortalVersionDifferent,
  setRefreshTimeout,
  signOut,
  disableErrorLogin,
  setRememberMe,
  isViewerOnlyInfo,
  setPortalVersion,
} = AuthSlice.actions;

export const AuthReducer = AuthSlice.reducer;
