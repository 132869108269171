import { Box } from "@mui/material";
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import themeInarix from "../../StylesMuiInarix";
import { ThemeProvider } from "@mui/material/styles";
import TableDashboardColumns from "./FetchingColumns";

export function DashboardTable(t: (arg0: string) => string, locale: string) {
  const { columnsValues, dashResults } = TableDashboardColumns(t);

  const paginationToolbar = () => {
    return (
      <GridFooterContainer
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
      >
        <GridPagination />
      </GridFooterContainer>
    );
  };

  return (
    <Box sx={{ height: 224, width: 1 }}>
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGrid
          rows={dashResults}
          columns={columnsValues}
          pageSizeOptions={[25, 50, 100]}
          slots={{ footer: paginationToolbar }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          rowHeight={23.5}
          sx={{ border: "none" }}
        />
      </ThemeProvider>
    </Box>
  );
}
