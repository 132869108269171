import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";

import {
  setDevicesModal,
  setUserDeviceMessageSuccess,
} from "../../slices/fleet/devices";

export const fetchDevices = createAsyncThunk(
  "DevicesReducer/fetchDevices",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const queryParams = [
      "$joinEager=[allotment.[user.[orgMemberships]], geoPoint]",
      "$select[]=device.id",
      "$select[]=device.deviceType",
      "$select[]=device.orgInternalId",
      "$select[]=device.ownerOrgId",
      "$select[]=device.lastAllotedUserId",
      "$select[]=device.geoPointId",
      "$select[]=device.createdAt",
      "$select[]=device.updatedAt",
      "device.deletedAt=null",
    ].join("&");

    try {
      const usersDevices = await axios.get(
        `${API}/users/device?${queryParams}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data } = usersDevices;

      return store.fulfillWithValue(data.data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const patchDeviceName = createAsyncThunk(
  "DevicesReducer/patchDeviceName",
  async (token: string, store) => {
    const { deviceId, deviceName } = (store.getState() as RootState)
      .DevicesReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.patch(
        `${API}/users/device/${deviceId}`,
        {
          orgInternalId: deviceName,
        },
        { headers }
      );
      const { data } = response;

      store.dispatch(setUserDeviceMessageSuccess(true));
      store.dispatch(setDevicesModal(false));
      store.dispatch(fetchDevices(token));
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
