/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2020-12-21 7:36:07 pm
 * @copyright Inarix
 */
import { combineReducers } from "redux";

// Reducers
/**
 * Authentication
 */
import {
  AuthState,
  AuthReducer as Authentication,
} from "./authentication/authentication";

import { LayoutState, layout as LayoutReducer } from "./layout";

import { UsersState, Users as UsersReducer } from "./fleet/users";
import { DeviceState, Devices as DevicesReducer } from "./fleet/devices";
import { ProjectsState, Projects as ProjectsReducer } from "./fleet/projects";
import {
  LocationsState,
  Locations as LocationsReducer,
} from "./fleet/locations";

import { MapState, MapRed as MapReducer } from "./map/map";
import {
  DashboardState,
  Dashboard as DashboardReducer,
} from "./dashboard/dashboard";
import { ActionsState, Actions as ActionsReducer } from "./fleet/actions";
import { DataState, Data as DataReducer } from "./data/data";
import {
  DataSampleviewState,
  DataSampleView as DataSampleViewReducer,
} from "./data/dataSampleView";

export interface State {
  Authentication: AuthState;
  LayoutReducer: LayoutState;
  UsersReducer: UsersState;
  MapReducer: MapState;
  DevicesReducer: DeviceState;
  LocationsReducer: LocationsState;
  ActionsReducer: ActionsState;
  DashboardReducer: DashboardState;
  ProjectsReducer: ProjectsState;
  DataReducer: DataState;
  DataSampleViewReducer: DataSampleviewState;
}

const rootReducer = combineReducers({
  Authentication,
  LayoutReducer,
  UsersReducer,
  MapReducer,
  DevicesReducer,
  LocationsReducer,
  ActionsReducer,
  DashboardReducer,
  ProjectsReducer,
  DataReducer,
  DataSampleViewReducer,
});

export default rootReducer;
