import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  resetStateMapMessages,
  setModalDeleteLocation,
  setNewLocationNewCluster,
  setSearchAddressResults,
  setSideBarGeoLocOpenClose,
  setNewValueMapUpdateCreateLocation,
  setResultsBoolean,
  setViewValues,
  initialState,
  setViewport,
  setLocationActivitySideBar,
} from "../../slices/map/map";

import mimeTypes from "mime-types";

import { NewGeoLocation } from "../../slices/map/mapTypes";
import { OrgGeoPointData } from "../locations/locationsTypes";
import { setConfirmModal, setInfoConfirmModal } from "../../slices/layout";
import {
  DashboardLocIdInfo,
  GeoLocInfoData,
} from "../dashboard/dashboardTypes";
import moment from "moment";
import { addHours, setZeroHrs } from "../dashboard/dashboardAsyncActions";

export const managingUndefined = (value: string | number) => {
  if (!value) {
    return undefined;
  } else {
    return value;
  }
};

export const uploadFile = (presignedUrl: string, file: File): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let contentType = mimeTypes.contentType(file.name);

    if (!contentType) {
      contentType = "text/plain";
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed with status code: ${xhr.status}`));
        }
      }
    };
    xhr.open("PUT", presignedUrl);
    xhr.setRequestHeader("Content-Type", contentType);
    xhr.send(file);
  });
};

export const fetchGeoLocActive = async (
  token: string,
  locale: string,
  newGeoLoc: NewGeoLocation | undefined
): Promise<any> => {
  const response = await axios({
    method: "GET",
    url: `${API}/users/organization-geo-point?$eager=[type, names]`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = response;

  const findGeo = data?.data?.find((locations: OrgGeoPointData) => {
    return locations.id === newGeoLoc?.id;
  });

  const newGeoLocData = {
    cluster: false,
    id: findGeo?.id,
    locationName: findGeo?.name,
    typeId: findGeo?.typeId,
    latitude: findGeo?.latitude,
    longitude: findGeo?.longitude,
    type: findGeo?.type,
  };
  return newGeoLocData;
};

export const uploadLocationImg2 = async (
  token: string,
  orgGeoId: number,
  mimeType: string,
  imgFile: File,
  locale: string
): Promise<any> => {
  try {
    const response = await axios({
      method: "POST",
      url: `${API}/users/organization-geo-point-image`,
      headers: {
        "Accept-Language": `${locale}`,
        Authorization: `Bearer ${token}`,
      },
      data: {
        orgGeoPointId: orgGeoId,
        mimeType: mimeType,
      },
    });

    const { data } = response;

    const url = data.signedRequest;
    await uploadFile(url, imgFile);

    return data;
  } catch (err: any) {
    console.log(err);
  }
};

export const fetchLocationsWithActivity = createAsyncThunk(
  "MapReducer/fetchLocationsWithActivity",
  async (token: string, store) => {
    const { locationDateActivity, isMapViewActivated } = (
      store.getState() as RootState
    ).MapReducer;

    const fromDate = setZeroHrs(locationDateActivity[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivity[1]))
      .toISOString()
      .slice(0, -1);

    const { locale } = (store.getState() as RootState).LayoutReducer;
    const queryParams = `/users/organization-geo-point?$eager=[type, names]&$modify[hasActivity][]=${fromDate}&$modify[hasActivity][]=${toDate}&$modify[unAckNotifications][]=${fromDate}&$modify[unAckNotifications][]=${toDate}&deletedAt=null`;

    try {
      const response = await axios.get(`${API}${queryParams}`, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;
      const locations = data.data;
      if (data.data.length > 0 && !isMapViewActivated) {
        const firstLocation = locations[locations.length - 1];

        store.dispatch(
          setViewport({
            latitude: parseInt(firstLocation.latitude),
            longitude: parseInt(firstLocation.longitude),
            zoom: 5.4430067895195045,
          })
        );
      }
      return store.fulfillWithValue(locations);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "MapReducer/deleteLocation",
  async (arg: { token: string; locationId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      await axios.delete(
        `${API}/users/organization-geo-point/${arg.locationId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );

      store.dispatch(setModalDeleteLocation(false));
      store.dispatch(setSideBarGeoLocOpenClose(false));
      store.dispatch(setNewLocationNewCluster(undefined));
      store.dispatch(fetchLocationsWithActivity(arg.token));
      store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchOrgGeoPointTypes = createAsyncThunk(
  "MapReducer/fetchOrgGeoPointTypes",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/organization-geo-point-type?$eager=names`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data } = response;

      return data.data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const getMapViews = createAsyncThunk(
  "MapReducer/getMapViews",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(
        `${API}/tools/saved-config?&typeConstantId=${process.env.REACT_APP_CONSTANT_ID_MAP}`,
        { headers }
      );
      const { data } = response;

      return data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const createNewView = createAsyncThunk(
  "MapReducer/mapCreateNewView",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { mapView, viewMapValues } = (store.getState() as RootState)
      .MapReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${API}/tools/saved-config`,
        {
          name: viewMapValues.nameView,
          description: viewMapValues.viewDescription,
          typeConstantId: parseInt(
            process.env.REACT_APP_CONSTANT_ID_MAP as string
          ),
          config: mapView,
        },
        { headers }
      );

      const { data } = response;
      store.dispatch(resetStateMapMessages());
      store.dispatch(setViewValues(initialState.viewMapValues));
      store.dispatch(getMapViews(token));
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteView = createAsyncThunk(
  "MapReducer/deleteView",
  async (token: string, store) => {
    const { locale, infoConfirmModal, sectionName } = (
      store.getState() as RootState
    ).LayoutReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.delete(
        `${API}/tools/saved-config/${infoConfirmModal.viewId}`,

        { headers }
      );

      if (sectionName === "/" || "/fleet/overview") {
        store.dispatch(setInfoConfirmModal(undefined));
        store.dispatch(setConfirmModal(false));
        store.dispatch(getMapViews(token));
      }
      if (sectionName === "/data") {
        // NO data views
      }

      const { data } = response;

      return data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const createLocation = createAsyncThunk(
  "MapReducer/createLocation",
  async (arg: { token: string; realmId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const {
      imgFile,
      newValuesEditCreateLocation,
      latitudeEditCreateGeoLoc,
      longitudeEditCreateGeoLoc,
    } = (store.getState() as RootState).MapReducer;

    const {
      name,
      type,
      primaryContactName,
      telephone,
      email,
      address,
      notes,
      countryName,
    } = newValuesEditCreateLocation;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/organization-geo-point`,
        {
          name,
          orgId: arg.realmId,
          typeId: parseInt(type),
          latitude: latitudeEditCreateGeoLoc,
          longitude: longitudeEditCreateGeoLoc,
          primaryContactName,
          telephone,
          email,
          address,
          notes,
          altitude: latitudeEditCreateGeoLoc ?? 0,
          countryName,
        },
        { headers }
      );

      const { data } = response;
      if (data && imgFile) {
        const contentType = mimeTypes.contentType(imgFile?.name || "");
        const imgUpdated = await uploadLocationImg2(
          arg.token,
          data.id,
          contentType as any,
          imgFile,
          locale
        );
        if (imgUpdated) {
          store.dispatch(fetchLocationsWithActivity(arg.token));
          store.fulfillWithValue("success");
        }

        if (!imgUpdated) {
          const err = new Error("Error");
          store.rejectWithValue(err);
        }
      } else {
        store.dispatch(fetchLocationsWithActivity(arg.token));
        store.dispatch(setInfoConfirmModal(false));
        store.dispatch(setConfirmModal(false));
        store.fulfillWithValue("success");
      }
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "MapReducer/updateLocation",
  async (
    arg: {
      token: string;
      geoLocId: number;
      latitude?: number;
      longitude?: number;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { newGeoLoc, imgFile, newValuesEditCreateLocation } = (
      store.getState() as RootState
    ).MapReducer;

    const {
      name,
      type,
      primaryContactName,
      telephone,
      email,
      address,
      notes,
      remoteId,
      countryName,
    } = newValuesEditCreateLocation;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.patch(
        `${API}/users/organization-geo-point/${arg.geoLocId}`,
        {
          name: managingUndefined(name),
          typeId: managingUndefined(parseInt(type)),
          remoteId: managingUndefined(remoteId),
          latitude: managingUndefined(arg.latitude as number),
          longitude: managingUndefined(arg.longitude as number),
          primaryContactName: managingUndefined(primaryContactName),
          telephone: managingUndefined(telephone),
          email: managingUndefined(email),
          address: managingUndefined(address),
          notes: managingUndefined(notes),
          altitude: 0,
          countryName: managingUndefined(countryName),
        },
        { headers }
      );

      const { data } = response;
      if (data && imgFile) {
        const contentType = mimeTypes.contentType(imgFile?.name || "");
        const imgUpdated = await uploadLocationImg2(
          arg.token,
          data.id,
          contentType as any,
          imgFile,
          locale
        );
        if (imgUpdated) {
          store.dispatch(fetchLocationsWithActivity(arg.token));
          const locationUpdated = await fetchGeoLocActive(
            arg.token,
            locale,
            newGeoLoc
          );

          store.dispatch(setNewLocationNewCluster(locationUpdated));
        }
        if (!imgUpdated) {
          const err = new Error("Error");
          store.rejectWithValue(err);
        }
      } else {
        store.dispatch(fetchLocationsWithActivity(arg.token));
        const locationUpdated = await fetchGeoLocActive(
          arg.token,
          locale,
          newGeoLoc
        );
        store.dispatch(setNewLocationNewCluster(locationUpdated));
      }
      store.dispatch(setInfoConfirmModal(false));
      store.dispatch(setConfirmModal(false));
      store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchLocationMapbox = createAsyncThunk(
  "MapReducer/fetchLocationMapbox",
  async (arg: { text: string }, store) => {
    const { newValuesEditCreateLocation } = (store.getState() as RootState)
      .MapReducer;

    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${arg.text}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
      );
      const { data } = response;
      const values = data.features;

      const found = values.filter((addressInfo: any) => {
        return addressInfo.id.startsWith("country");
      });

      store.dispatch(
        setSearchAddressResults(data?.features.map((l: any) => l.place_name))
      );

      store.dispatch(
        setNewValueMapUpdateCreateLocation({
          ...newValuesEditCreateLocation,
          address: data?.features[0].place_name,
          countryName: found[0].place_name,
        }) as any
      );
    } catch (error) {
      store.rejectWithValue(error);
    }
  }
);

export const fetchLocationImg = createAsyncThunk(
  "MapReducer/fetchLocationImg",
  async (arg: { token: string; orgGeoId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `${API}/users/organization-geo-point-image/${arg.orgGeoId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;
      const handleUndefined = () => {
        if (data.signedRequest === undefined) {
          return null;
        } else {
          return data.signedRequest as string;
        }
      };

      return store.fulfillWithValue(handleUndefined());
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchAllUsersIds = createAsyncThunk(
  "MapReducer/fetchAllUsersIds",
  async (token: string, store) => {
    const { locationDateActivity, newGeoLoc } = (store.getState() as RootState)
      .MapReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const fromDate = setZeroHrs(locationDateActivity[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivity[1]))
      .toISOString()
      .slice(0, -1);

    const fromDateToISO = `${new Date(fromDate).getFullYear()}-${
      new Date(fromDate).getMonth() + 1
    }-${new Date(fromDate).getDate()}`;

    const toDateToISO = `${new Date(toDate).getFullYear()}-${
      new Date(toDate).getMonth() + 1
    }-${new Date(toDate).getDate()}`;

    if (newGeoLoc) {
      try {
        const response = await axios.get(
          `${API}/samples/past-action?$joinRelation=creator&orgGeoPointId=${newGeoLoc?.id}&past_action.createdAt[$gte]=${fromDateToISO}&past_action.createdAt[$lte]=${toDateToISO}&past_action.taintedAt=null&$select[]=creator.username&$sort[past_action.createdAt]=-1`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": `${locale}`,
            },
          }
        );
        const { data } = response;
        const resultPerDeviceId = data.data.reduce((data: any, result: any) => {
          if (data[result.username]) {
            data[result.username]++;
          } else {
            data[result.username] = 1;
          }
          return data;
        }, {});

        const dataTransformed = Object.entries(resultPerDeviceId).map(
          ([username, count]) => ({
            username,
            count: parseInt(`${count}`) as unknown as string,
          })
        );

        store.dispatch(setLocationActivitySideBar(dataTransformed));
      } catch (error) {
        return store.rejectWithValue(error);
      }
    }
  }
);

export const fetchGeoLocDataOverviewMini = createAsyncThunk(
  "MapReducer/fetchGeoLocDataOverviewMini",
  async (arg: { token: string; id: DashboardLocIdInfo[] }, store) => {
    const { locationDateActivity } = (store.getState() as RootState).MapReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const fromDate = setZeroHrs(locationDateActivity[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivity[1]))
      .toISOString()
      .slice(0, -1);

    const idLocationsSelected = arg.id?.map((locations: DashboardLocIdInfo) => {
      return `id[$in][]=${locations.id}&`;
    });

    const idsTransformed = idLocationsSelected?.join("");

    const paramsAllSilos = [
      `activityPerDay[]=${fromDate}`,
      `activityPerDay[]=${toDate}`,
      `actionsPerBarleyVar[]=${fromDate}`,
      `actionsPerBarleyVar[]=${toDate}`,
      `avgProtPerBarleyVar[]=${fromDate}`,
      `avgProtPerBarleyVar[]=${toDate}`,
      `actionsPerSoftWheatVar[]=${fromDate}`,
      `actionsPerSoftWheatVar[]=${toDate}`,
      `avgProtPerSoftWheatVar[]=${fromDate}`,
      `avgProtPerSoftWheatVar[]=${toDate}`,
    ].join("&");

    try {
      const response = await axios.get(
        `${API}/users/organization-geo-point?${idsTransformed}${paramsAllSilos} `,

        {
          headers: {
            Authorization: `Bearer ${arg.token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );
      const transformData = response.data.data
        .map((loc: GeoLocInfoData) => loc.activity)
        .filter((x: any) => !!x);

      const arrayTransformed = transformData
        .map((record: any) =>
          Object.entries(record).map(([date, count]) => ({
            count: parseInt(`${count}`),
            date,
          }))
        )
        .flat();

      const cache: Record<string, number> = [
        ...new Set(arrayTransformed.map(({ date }: { date: string }) => date)),
      ].reduce(
        ((previous: Record<string, number>, date: string) => {
          previous[date] = 0;
          return previous;
        }) as any,
        {}
      ) as Record<string, number>;

      arrayTransformed.forEach(
        ({ count, date }: { count: number; date: string }) => {
          cache[date] += count;
        }
      );

      const finalArray = Object.keys(cache).map((date) => ({
        date: date,
        count: cache[date].toString(),
      }));

      const arrayOfObj = finalArray;

      arrayOfObj.sort(
        (
          a: { count: string; date: string },
          b: { count: string; date: string }
        ): number => {
          if (moment(a.date).isBefore(moment(b.date))) return -1;
          if (moment(a.date).isAfter(moment(b.date))) return 1;
          return 0;
        }
      );

      let fromDateConvert = moment(fromDate);
      const toDateConvert = moment(toDate);
      let i = 0;
      const finalActivity = [];

      while (!fromDateConvert.isSame(toDateConvert, "day")) {
        if (i < arrayOfObj.length) {
          const d = moment(arrayOfObj[i].date);
          if (fromDateConvert.isSame(d, "day")) {
            finalActivity.push(arrayOfObj[i]);
            i++;
          } else {
            finalActivity.push({
              count: "0",
              date: fromDateConvert.toISOString(),
            });
          }
        } else {
          finalActivity.push({
            date: fromDateConvert.toISOString(),
            count: "0",
          });
        }
        fromDateConvert = fromDateConvert.add(1, "days");
      }

      return store.fulfillWithValue(finalActivity);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
